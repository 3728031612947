<template>
  <div class="article">
    <div class="schedule_wrap">
      <div class="schedule_head">
        <p>조회시간 : {{ str_time }}</p>
        <button type="button" class="btn_sub1 refresh_btn" @click="refreshData">
          재조회
        </button>
        <button
          type="button"
          class="btn_sub1"
          v-show="fullBtn"
          @click.prevent.stop="
            (fullScreen = !fullScreen), $emit('fullScreen', fullScreen)
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="schedule" id="calendar"></div>
    </div>
  </div>
</template>

<script>
import { Calendar } from '@fullcalendar/core';
import InteractionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import FETCH_MIXIN from '@/mixins/fetch';
import MODAL_MIXIN from '@/mixins/modal';
import { mapGetters } from 'vuex';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  props: ['fullBtn'],
  mixins: [FETCH_MIXIN, MODAL_MIXIN],
  data() {
    return {
      showCalender: false,
      fullScreen: false,
      calendar: null,
      isCalendarModalOpen: false,
      dateString: '',
      dateString2: '',
      date: null,
      rendering: 0,
      nowWeeks: [],
      str_time: null,
    };
  },
  computed: {
    ...mapGetters({
      monthData: 'getCalendarMonthFromCalendar',
      calendarOptions: 'getCalendarOptionsFromMainCalendar',
      month: 'getMonthFromCalendar',
      year: 'getYearFromCalendar',
      users: 'getUsersFromUserPage',
      userId: 'getUserId',
      user_role_type: 'getUserRoleTypeFromUserPage',
      lots: 'getLot',
      products: 'getProduct',
      units: 'getUnitCodes',
    }),
    // 바꿀 부분
    filterData() {
      if (this.monthData !== undefined && this.monthData.length > 0) {
        let clone = this.lodash.clonedeep(this.monthData);
        if (this.calendarOptions !== undefined) {
          let cloneOpt = this.lodash
            .clonedeep(this.calendarOptions)
            .filter(x => x.setting == true)
            .map(x => x.id);
          let checkedTypeId = clone.filter(x =>
            cloneOpt.includes(x.system_event_type_id),
          );
          checkedTypeId.forEach((el, index) => {
            el.start = checkedTypeId[index].create_time
              .replace(/ /gi, 'T')
              .substr(0, 19);
          });
          return checkedTypeId;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    filter_lot_date() {
      if (this.lots.length < 1) {
        return [];
      } else {
        const clone = this.lodash.clonedeep(this.lots);
        const map = clone.map(x => {
          x.description =
            this.$makeComma(x.quantity) +
            this.findInfoFromId(
              this.units,
              this.findInfoFromId(this.products, x.product_id).stock_unit_id,
            ).name;
          x.input_month = x.input_date.substr(0, 7);
          x.system_event_type_id = 4;
          x.system_event_type_id2 = 4;
          x.title = this.findInfoFromId(this.products, x.product_id).name;
          x.start =
            x.input_date + ' 00:00:00.000000'.replace(/ /gi, 'T').substr(0, 19);
          return x;
        });
        return map;
      }
    },
  },
  methods: {
    async refreshData() {
      if (this.nowWeeks.length < 1) {
        return;
      } else {
        await this.FETCH_WITH_PAYLOAD('FETCH_LOT_DAY', {
          start: this.nowWeeks[0],
          end: this.nowWeeks[6],
        });
        this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
        this.reRendCalendar();
      }
    },
    async reRendCalendar() {
      let events = this.filter_lot_date;

      function weekdayEn(dateStr) {
        const week = ['Sun', 'Mon', 'Tues', 'Wednes', 'Thurs', 'Fri', 'Satur'];
        let dayOfWeek = week[new Date(dateStr).getDay()];
        return dayOfWeek;
      }
      function MonthdayEn(dateStr) {
        const monthNames = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        let months = monthNames[new Date(dateStr).getMonth()];
        return months;
      }
      function convertOrdinalNumber(n) {
        n = parseInt(n, 10);
        const suffix = ['th', 'st', 'nd', 'rd'];
        const mod100 = n % 100;
        return n + (suffix[(mod100 - 20) % 10] || suffix[mod100] || suffix[0]);
      }
      let calendarEl = document.getElementById('calendar');
      this.calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, InteractionPlugin],
        height: 'auto',
        contentHeight: '100%',
        expandRows: true,
        eventDisplay: 'block',
        slotMinTime: '08:00',
        slotMaxTime: '22:00',
        stickyHeaderDates: false,
        headerToolbar: {
          left: 'title',
          center: '',
          right: '',
        },
        titleFormat: date => {
          console.log(date);
          let today = new Date();
          return `${weekdayEn(today)}day, ${MonthdayEn(
            today,
          )} ${convertOrdinalNumber(today.getDate())}`;
        },
        fixedWeekCount: true,
        initialView: 'dayGridWeek',
        editable: true,
        selectable: false,
        events,
        eventDidMount: function(info) {
          let detail = events.find(x => x.id == info.event.id).description;
          info.el.append(detail);
        },
      });
      this.calendar.render();
    },
  },

  async mounted() {
    let events = this.filter_lot_date;

    function weekdayEn(dateStr) {
      const week = ['Sun', 'Mon', 'Tues', 'Wednes', 'Thurs', 'Fri', 'Satur'];
      let dayOfWeek = week[new Date(dateStr).getDay() + 1];
      return dayOfWeek;
    }
    function MonthdayEn(dateStr) {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      let months = monthNames[new Date(dateStr).getMonth()];
      return months;
    }
    function convertOrdinalNumber(n) {
      n = parseInt(n, 10);
      const suffix = ['th', 'st', 'nd', 'rd'];
      const mod100 = n % 100;
      return n + (suffix[(mod100 - 20) % 10] || suffix[mod100] || suffix[0]);
    }
    let calendarEl = document.getElementById('calendar');
    this.calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, InteractionPlugin],
      height: 'auto',
      contentHeight: '100%',
      expandRows: true,
      eventDisplay: 'block',
      slotMinTime: '08:00',
      slotMaxTime: '22:00',
      stickyHeaderDates: false,
      headerToolbar: {
        left: 'title',
        center: '',
        right: '',
      },
      titleFormat: date => {
        console.log(date);
        let today = new Date();
        return `${weekdayEn(today)}day, ${MonthdayEn(
          today,
        )} ${convertOrdinalNumber(today.getDate())}`;
      },
      fixedWeekCount: true,
      initialView: 'dayGridWeek',
      editable: false,
      selectable: false,
      events,
    });
    this.calendar.render();
  },
  async created() {
    if (this.nowWeeks.length < 1) {
      var currentDay = new Date();
      var theYear = currentDay.getFullYear();
      var theMonth = currentDay.getMonth();
      var theDate = currentDay.getDate();
      var theDayOfWeek = currentDay.getDay();

      var thisWeek = [];

      for (var i = 0; i < 7; i++) {
        var resultDay = new Date(
          theYear,
          theMonth,
          theDate + (i - theDayOfWeek),
        );
        var yyyy = resultDay.getFullYear();
        var mm = Number(resultDay.getMonth()) + 1;
        var dd = resultDay.getDate();

        mm = String(mm).length === 1 ? '0' + mm : mm;
        dd = String(dd).length === 1 ? '0' + dd : dd;

        thisWeek[i] = yyyy + '-' + mm + '-' + dd;
      }
      this.nowWeeks = thisWeek;
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    await this.FETCH_WITH_PAYLOAD('FETCH_LOT_DAY', {
      start: this.nowWeeks[0],
      end: this.nowWeeks[6],
    });
    this.reRendCalendar();
  },
};
</script>
