var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    status_board_saerom: _vm.tabIndex == 0,
    weekly_production_target: _vm.tabIndex == 0,
    plc_th_status: _vm.tabIndex == 0,
    plc_th_log: _vm.tabIndex == 1,
    default_setting: _vm.tabIndex == 2,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToPlcMonitorPage',"options":[
          { title: '실시간 온습도 현황', name: 'realtime_th_status' },
          { title: '온습도 현황', name: 'temp_log_status' },
          { title: '기준값 설정', name: 'default_plc_setting' } ]}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',[_c('li',{class:{ active: _vm.tabIndex == 0 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(0)}}},[_vm._v("실시간 온습도 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 1 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(1)}}},[_vm._v("온습도 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 2 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(2)}}},[_vm._v("기준값 설정")])])])]):_vm._e(),(_vm.tabIndex == 0)?_c('div',{staticClass:"swiper-container",class:{ active: _vm.slideGo }},[_c('div',{staticClass:"swiper-wrapper"},[(_vm.tabIndex == 0)?_c('temp-real-time-form',{staticClass:"swiper-slide",attrs:{"fullBtn":_vm.fullBtn},on:{"fullScreen":function($event){_vm.slideGo = $event}}}):_vm._e(),(_vm.tabIndex == 0 && _vm.slideGo)?_c('weekly-production-target-form',{staticClass:"swiper-slide",on:{"fullScreen":function($event){_vm.slideGo = $event}}}):_vm._e()],1)]):_vm._e(),(_vm.tabIndex == 1)?_c('temp-log-form'):_vm._e(),(_vm.tabIndex == 2)?_c('default-plc-form'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }