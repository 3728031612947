<template>
  <div class="article">
    <div class="card_wrap">
      <div class="card_head">
        <h5>온습도 현황</h5>
        <span
          >조회시간:
          {{
            filtered_temp_only_sensor_list.length > 0
              ? yyyyMMddHHmmss(
                  new Date(filtered_temp_only_sensor_list[0].create_time),
                )
              : ''
          }}</span
        >
        <button
          type="button"
          class="btn_sub1"
          v-show="fullBtn"
          @click.prevent.stop="
            (fullScreen = !fullScreen), $emit('fullScreen', fullScreen)
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="card_body">
        <div>
          <h5>인터페이스</h5>
          <ul class="th_card_wrap">
            <li
              class="th_card"
              v-for="n in filtered_temp_only_sensor_list"
              :key="n.id"
              :class="{ warning: n.warning }"
            >
              <h5>{{ n.plc_sensor_name }}</h5>
              <div>
                <p>온도</p>
                <span>{{ n.value }} ℃</span>
              </div>
              <!-- <div>
                <p>습도</p>
                <span>48 %</span>
              </div> -->
            </li>
          </ul>
        </div>
        <!-- sotorage_card == 빨간색 / material_storage_card == 청색 -->
        <div>
          <h5>보관창고</h5>
          <ul class="storage_wrap">
            <li
              :class="
                item.temp.warning || item.humi.warning
                  ? 'storage_card'
                  : 'material_storage_card'
              "
              v-for="(item, index) in filtered_temp_humi_sensor_list"
              :key="index"
            >
              <h5>
                {{ item.temp.plc_location_name }}
              </h5>
              <div class="chart_wrap">
                <div class="center_txt">
                  <p>{{ item.temp.value }} ℃</p>
                </div>
                <doughnut-chart
                  :data="item.graph_data"
                  :options="chartData.options"
                ></doughnut-chart>
              </div>
              <p>{{ item.humi.value }} %</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from '@/layouts/components/charts/DoughnutChart';
import FETCH_MIXIN from '@/mixins/fetch';
import { mapGetters } from 'vuex';

export default {
  props: ['fullBtn'],
  mixins: [FETCH_MIXIN],
  components: {
    DoughnutChart,
  },
  data() {
    return {
      fullScreen: false,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_sensors: 'getPlcSensor',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
      plc_sensor_logs_temp: 'getPlcSensorLogTemp',
    }),
    filtered_temp_only_sensor_list() {
      if (this.plc_sensor_logs.length < 1 || this.plc_sensors.length < 1) {
        return [];
      } else {
        const plc_sensor_logs = this.lodash.clonedeep(this.plc_sensor_logs);
        const filter = this.lodash
          .clonedeep(this.plc_sensors)
          .filter(x => x.plc_sensor_type_id == 2)
          .map(x => {
            let log = plc_sensor_logs.find(y => y.plc_sensor_id == x.id);
            if (log == undefined) {
              console.log(plc_sensor_logs);
              log = this.plc_sensor_logs_temp.find(
                y => y.plc_sensor_id == x.id,
              );
            }
            x.value = log.value;
            x.plc_sensor_name = log.plc_sensor_name;
            x.create_time = log.create_time;

            if (x.value > x.max_value || x.value < x.min_value) {
              x.warning = true;
            } else {
              x.warning = false;
            }
            return x;
          });
        return filter.sort((a, b) => a.plc_sensor_name - b.plc_sensor_name);
      }
    },
    filtered_temp_humi_sensor_list() {
      if (this.plc_sensor_logs.length < 1 || this.plc_sensors.length < 1) {
        return [];
      } else {
        const filter = this.lodash
          .clonedeep(this.plc_sensors)
          .filter(x => x.plc_sensor_type_id == 1)
          .sort((a, b) => a.plc_sensor_name - b.plc_sensor_name);
        let tempFilter = filter.filter(x => x.plc_sensor_detail_type_id == 1);
        let humiFilter = filter.filter(x => x.plc_sensor_detail_type_id == 2);
        tempFilter = tempFilter
          .sort((a, b) => a.plc_sensor_name - b.plc_sensor_name)
          .map(x => {
            let log = this.plc_sensor_logs.find(y => y.plc_sensor_id == x.id);
            if (log == undefined) {
              console.log(this.plc_sensor_logs);
              log = this.plc_sensor_logs_temp.find(
                y => y.plc_sensor_id == x.id,
              );
            }
            x.plc_sensor_name = log.plc_sensor_name;
            x.plc_location_name = log.plc_location_name;
            x.value = log.value;
            x.create_time = log.create_time;
            if (x.value > x.max_value || x.value < x.min_value) {
              x.warning = true;
            } else {
              x.warning = false;
            }
            return x;
          });
        humiFilter = humiFilter
          .sort((a, b) => a.plc_sensor_name - b.plc_sensor_name)
          .map(x => {
            let log = this.plc_sensor_logs.find(y => y.plc_sensor_id == x.id);
            if (log == undefined) {
              log = this.plc_sensor_logs_temp.find(
                y => y.plc_sensor_id == x.id,
              );
            }
            x.plc_sensor_name = log.plc_sensor_name;
            x.plc_location_name = log.plc_location_name;
            x.value = log.value;
            x.create_time = log.create_time;
            if (x.value > x.max_value || x.value < x.min_value) {
              x.warning = true;
            } else {
              x.warning = false;
            }

            return x;
          });
        let returnData = [];
        tempFilter = tempFilter.sort(
          (a, b) => a.plc_location_id - b.plc_location_id,
        );
        humiFilter = humiFilter.sort(
          (a, b) => a.plc_location_id - b.plc_location_id,
        );
        humiFilter.forEach((el, index) => {
          let backgroundColor = ['#26ffff', '#bbd1ff'];
          if (el.warning || tempFilter[index].warning) {
            backgroundColor = ['#f18484', '#f1ddda'];
          }

          let data = {
            datasets: [
              {
                data: [el.value, 100 - el.value],
                fill: false,
                pointRadius: 4,
                backgroundColor: backgroundColor,
                lineTension: 0,
              },
            ],
          };
          returnData.push({
            temp: tempFilter[index],
            humi: humiFilter[index],
            graph_data: data,
          });
        });

        return returnData;
      }
    },

    chartData() {
      let options = {
        legend: {
          display: false,
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        cutoutPercentage: 78,
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: false,
        },
      };

      return {
        options: options,
      };
    },
  },

  methods: {
    yyyyMMddHHmmss(date) {
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      var hh = date.getHours();
      var mm = date.getMinutes();
      var ss = date.getSeconds();

      return [
        date.getFullYear(),
        '-' + (MM > 9 ? MM : '0' + MM),
        '-' + (dd > 9 ? dd : '0' + dd),
        ' (' + (hh > 9 ? hh : '0' + hh),
        ':' + (mm > 9 ? mm : '0' + mm),
        ':' + (ss > 9 ? ss : '0' + ss),
        ')',
      ].join('');
    },
    async fetch_plc_sensor_log_now() {
      await this.$store
        .dispatch('FETCH_PLC_SENSOR_LOG_NOW')
        .then(response => {
          this.$store.commit(
            'setPlcSensorLogTemp',
            this.lodash.clonedeep(response.data.data_list),
          );
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            `온습도 정보를 불러오는 중 오류가 발생하였습니다.`,
          );
        })
        .finally(() => {});
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
  },
  async created() {
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');

    this.timerCount = this.timerMax;
    this.fetch_plc_sensor_log_now();

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        this.fetch_plc_sensor_log_now();
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
  },
};
</script>
