<template>
  <div
    id="contents"
    :class="{
      status_board_saerom: tabIndex == 0,
      weekly_production_target: tabIndex == 0,
      plc_th_status: tabIndex == 0,
      plc_th_log: tabIndex == 1,
      default_setting: tabIndex == 2,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="[
            { title: '실시간 온습도 현황', name: 'realtime_th_status' },
            { title: '온습도 현황', name: 'temp_log_status' },
            { title: '기준값 설정', name: 'default_plc_setting' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">실시간 온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">기준값 설정</a>
          </li>
        </ul>
      </div>
      <div
        class="swiper-container"
        v-if="tabIndex == 0"
        :class="{ active: slideGo }"
      >
        <div class="swiper-wrapper">
          <temp-real-time-form
            v-if="tabIndex == 0"
            class="swiper-slide"
            @fullScreen="slideGo = $event"
            :fullBtn="fullBtn"
          ></temp-real-time-form>
          <weekly-production-target-form
            v-if="tabIndex == 0 && slideGo"
            class="swiper-slide"
            @fullScreen="slideGo = $event"
          ></weekly-production-target-form>
        </div>
      </div>
      <temp-log-form v-if="tabIndex == 1"></temp-log-form>
      <default-plc-form v-if="tabIndex == 2"></default-plc-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import WeeklyProductionTargetForm from '@/views/forms/Custom/Monitor/9/WeeklyProductionTargetForm';
import TempRealTimeForm from '@/views/forms/Custom/Monitor/9/TempRealTimeForm';
import TempLogForm from '@/views/forms/Custom/Monitor/9/TempLogForm';
import DefaultPlcForm from '@/views/forms/Custom/Monitor/9/DefaultPlcForm';
import FETCH_MIXIN from '@/mixins/fetch';
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use(Autoplay);

export default {
  mixins: [ClosePageMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    AsideSelectric,
    WeeklyProductionTargetForm,
    TempRealTimeForm,
    TempLogForm,
    DefaultPlcForm,
  },
  data() {
    return {
      swiper: null,
      slideGo: false,
      fullBtn: true,
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  mounted() {
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 1,
      freeMode: true,
      resizeReInit: true,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 20000,
        disableOnInteraction: false,
      },
    });
    if (this.tabIndex == 0) {
      this.swiper.autoplay.stop();
      this.swiper.allowTouchMove = false;
    }
  },
  updated() {
    this.$nextTick(() => {
      if (this.slideGo) {
        if (this.tabIndex == 0) {
          this.swiper.autoplay.start();
          this.swiper.allowTouchMove = true;
        } else {
          this.swiper.autoplay.stop();
          this.swiper.allowTouchMove = false;
        }
      }
    });
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'saerom_monitoring';
</style>
